export default {

  meses: [
    {value: 1, nome:  'Janeiro'},
    {value: 2, nome:  'Fevereiro'},
    {value: 3, nome:  'Março'},
    {value: 4, nome:  'Abril'},
    {value: 5, nome:  'Maio'},
    {value: 6, nome:  'Junho'},
    {value: 7, nome:  'Julho'},
    {value: 8, nome:  'Agosto'},
    {value: 9, nome:  'Setembro'},
    {value: 10, nome:  'Outubro'},
    {value: 11, nome:  'Novembro'},
    {value: 12, nome:  'Dezembro'},
  ],


  tipoDatas: [
    { value: 'data_lancamento', nome:'Data Lançamento'},
    { value: 'data_entregue', nome:'Data Entregue/Finalizado'},
    { value: 'data_prazo', nome:'Data Prazo'},
  ],

  diasSemana: {
    0: 'Dom',
    1: 'Seg',
    2: 'Ter',
    3: 'Qua',
    4: 'Qui',
    5: 'Sex',
    6: 'Sáb'
  }

}
