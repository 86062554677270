<template>
  <div v-if="pedido.ficha" id="pedidoImprimir">
    <h4 class="text-center mb-5">Pedido {{ pedido.ficha.id }}</h4>
    <hr />
    <b>Data Pedido : </b> {{ pedido.ficha.data_lancamento | converteData }} <br />
    <b>Paciente : </b> {{ pedido.ficha.paciente }} <br />
    <b>Materiais enviados : </b>
    <span v-for="(m, index) in pedido.ficha.materiais" :key="index">
      {{ m.nome }} ({{ m.qtd }})
    </span>
    <br />

    <hr>
    <table>
      <thead>
        <th width="80">QTD</th>
        <th width="180">SERVIÇO/PRODUTO</th>
        <th width="180">Nº DENTE</th>
        <th width="180">COR DENTE</th>
        <th width="250">PRAZO</th>
        <th width="270">VALOR UNITÁRIO</th>
        <th width="200">DESCONTO</th>
        <th width="200">SUBTOTAL</th>
      </thead>
      <tbody v-for="(item, index) in pedido.ficha.items" :key="index">
      <tr>
        <td>{{ item.quantidade }}</td>
        <td v-if="item.servico_id">
          {{ pedido.servicos[item.servico_id][0].servico_nome }}
        </td>
        <td v-else-if="item.produto_id">
          {{ pedido.produtos[item.produto_id][0].nome }}
        </td>
        <td v-else>Custo com entrega</td>
        <td>{{ item.num_dente }}</td>
        <td>{{ item.cor_dente }}</td>
        <td>{{ item.data_prazo_lab | converteData }}</td>
        <td>{{ item.valor | formataMoeda }}</td>
        <td>{{ item.desconto | formataMoeda }}</td>
        <td>{{ item.valor_total | formataMoeda }}</td>
      </tr>

      <tr>
        <td colspan="8"> <strong>Observação: </strong> {{ item.observacao_item }}</td>
      </tr>

      <tr>
        <td colspan="8" style="border-bottom: #ccc 1px solid; height: 15px"></td>
      </tr>

      </tbody>
    </table>
    <h4 class="float-right mt-2">Total R$ {{ valorTotal | formataMoeda }}</h4>
  </div>
</template>

<script>
export default {
  name: "ImprimirRelatorio",
  props: ["pedido"],

  data() {
    return {
      valorTotal: 0
    };
  },

  watch: {
    pedido(pedido) {
      if (pedido) {
        this.caculaTotal()
      }
    },

  },

  computed: {
    /* periodo() {
      return this.pedidos.sort(function (x, y) {
        let a = new Date(x.data_lancamento),
          b = new Date(y.data_lancamento);
        return a - b;
      });
    }, */
  },
  methods: {
    caculaTotal() {
     let total = this.pedido.ficha.items.reduce((total, element) => total + Number(element.valor_total), 0)
      this.valorTotal = total
    }
  },

  filters: {
    converteData(data) {
      if (!data) return;

      const d = data.split("-");
      return `${d[2]}/${d[1]}/${d[0]}`;
    },
    formataMoeda(valor) {
      if (!valor) return "0,00";

      return valor.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>

<style scoped>
</style>
