<template>
  <b-modal
      id="modalAddArquivos"
      hide-footer
      centered
      size="lg"
      title="Adicionar Arquivos Pedido"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
  >

    <b-card v-if="getErrors.hasMessage">
      <b-row>
        <b-col cols="12">
          <b-alert :variant="getErrors.color"  show>
            <div class="alert-body" v-for="(error, index) in getErrors.messageArray" :key="index">
              {{error[0]}}
            </div>
            <div class="alert-body">{{getErrors.message}}</div>
          </b-alert>
        </b-col>
      </b-row>
    </b-card>

    <b-row>
      <b-col>
        <small v-if="statusUpload !== null" class="text-danger">Permitido no máximo 30 arquivos, para quantias maiores deve ser compactado antes em formato Zip ou Rar!</small>
        <b-form-file
            placeholder="Selecione Imagens"
            multiple
            accept="image/*, .pdf, .stl, .zip, .dcm, .rar, .mov, .mp4, .mkv"
            browse-text="Upload"
            v-model="files"
            :state="statusUpload"
            @change="filesUpload"
        >
          <template slot="file-name" slot-scope="{ names }">
            <b-badge v-if="index < 3" class="mr-1" variant="light-primary" v-for="(name, index) in names" :key="index">{{ name }}</b-badge>
            <b-badge v-if="names.length > 3" variant="secondary" class="ml-1">
              + {{ names.length - 3 }} Mais Arquivo(s)
            </b-badge>
          </template>
        </b-form-file>
      </b-col>
      <b-col cols="2" v-if="files.length">
        <b-button variant="outline-danger" @click="files = []">Limpar</b-button>
      </b-col>
    </b-row>

    <b-card v-if="files.length" title="Upload Arquivos">
      <div class="demo-vertical-spacing" v-for="file in files">
        <div class="progress-wrapper mb-1">
          <b-card-text class="mb-0">
            <strong class="text-primary"> {{ file.name }} {{ uploadProgress[file.name] +'%' }} </strong>
            <strong class="float-right text-primary"> {{ file.size | convertToMb }} MB </strong>
          </b-card-text>
          <b-progress
              v-model.sync="uploadProgress[file.name]"
              max="100"
              :variant="uploadProgress[file.name] < 100 ? 'primary' : 'success' "
          />
        </div>
      </div>
    </b-card>

    <b-card v-if="errorUploadFiles.length" title="Falha Upload Arquivos">
      <div class="demo-vertical-spacing" v-for="fileError in errorUploadFiles">
        <div class="progress-wrapper mb-1">
          <b-card-text class="mb-0">
            <strong class="text-danger"> {{ fileError }} </strong>
          </b-card-text>
        </div>
      </div>
    </b-card>

    <b-row class="mt-2">
      <b-col v-if="statusUpload || statusUpload === null">
        <b-button
            variant="gradient-success"
            block
            :disabled="submit"
            @click="handleFilesUpload"
        >
          Enviar Arquivos
        </b-button>
      </b-col>
      <b-col>
        <b-button
            variant="outline-secondary"
            block
            @click="fecharModal"
        >
          Fechar
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>

import {
  BAlert,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BModal,
  BRow,
  BDropdown,
  BDropdownItem, BCardText, BProgress, BFormFile,

} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from "axios";


export default {

  props: ['pedidoId','laboratorios'],
  components:{
    BCardText,
    BFormFile,
    BProgress,
    BBadge,
    BCard,
    BAlert,
    BModal,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    ToastificationContent,
  },

  data() {
    return {
      getErrors: {
        hasMessage: false,
        color: "danger",
        message: "",
        messageArray: "",
      },
      progressArr: [],
      errorUploadFiles: [],
      uploadProgress: [],
      files: [],
      statusUpload: null,
      submit: false
    }
  },

  watch: {
    files(newValue) {
      this.statusUpload = null
      if (newValue && newValue.length > 30) {
        this.statusUpload = false
      }
    }
  },
  methods: {

    fecharModal() {
      this.$emit('buscaEntradas')
      this.files = []
      this.$bvModal.hide('modalAddArquivos')
    },
    filesUpload(e){

      this.qtdFiles = 0;
      this.files = [];
      let selectedFiles = e.target.files;

      if(!selectedFiles.length){
        return false;
      }
      for(let i=0;i<selectedFiles.length;i++) {
        this.files.push(selectedFiles[i]);
        this.$set(this.uploadProgress, selectedFiles[i].name, 0);

        this.qtdFiles = selectedFiles.length;
      }

    },
    getUniqueFileName(file) {
      const parts = file.name.split('.');
      const extension = parts.pop();
      const fileName = parts.join('.');
      const randomString = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

      return {
        nome: `${fileName}-${randomString}.${extension}`,
        ext: extension
      }
    },

    getType(ext) {
      const imageExtensions = ['png', 'jpg', 'jpeg', 'bmp'];

      if(ext && imageExtensions.includes(ext)) {
        return 1;
      }

      return 2;
    },

    async handleFilesUpload() {

      this.submit = true
      const files = this.files;
      const s3AxiosInstance = axios.create();

      for (let i = 0; i < files.length; i++) {

        const uniqueFileName = this.getUniqueFileName(files[i]);

        const presignedData = await this.$http.get(`https://smartprotese.app/api/v1/odontodocs/pedidos/uploads/get_url?nome=${uniqueFileName.nome}`);
        const presignedUrl = presignedData.data;

        await s3AxiosInstance.put(presignedUrl, files[i], {
          headers: {
            "Content-Type": files[i].type
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            this.$set(this.uploadProgress, files[i].name, percentCompleted);
          }
        });

        let fileStore = {
          url: uniqueFileName.nome,
          nome: files[i].name,
          tamanho: (files[i].size / 1048576).toFixed(2),
          tipo: this.getType(uniqueFileName.ext),
        }

        this.filesStore(fileStore)
      }

      this.getErrors = {
        hasMessage: true,
        color: 'success',
        message: 'Upload concluído'
      }

    },

    filesStore(file) {

      this.$http.post(`odontodocs/pedidos/uploads/${this.pedidoId}/${this.laboratorios}`, file)
          .then()
          .catch( error => {
            this.errorUploadFiles.push(file.nome)
          })
    },
  },
  filters: {
    convertToMb(size) {
      return (size / 1048576).toFixed(2)
    }
  }
}

</script>

<style>


</style>

