<template>
  <b-modal
      id="imagemSlide"
      ref="myCarousel"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      centered
      size="lg"
      title="Imagens"
  >
    <b-carousel
        id="carousel-example-generic"
        controls
        indicators
        v-model.sync="indexComputado"
    >
      <b-carousel-slide v-for="(img, idx) in imagens" :img-src="`https://smartprotese.s3.sa-east-1.amazonaws.com/smartprotese/${img.url}`" :key="idx"/>
    </b-carousel>
  </b-modal>
</template>

<script>

import { BModal, BCarousel, BCarouselSlide, BButton, } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: "ModalSlides",
  directives: {
    Ripple,
  },
  props: ['imagens', 'index'],
  components: {
    BModal,
    BCarousel,
    BCarouselSlide,
    BButton,
  },
  data() {
    return {
      slide: 2
    }
  },
  computed: {
    indexComputado: {
      get() {
        return this.index
      },
      set(val) {
        this.$emit('mudouIndex', val)
      }
    }
  },

}
</script>

<style scoped>

</style>
