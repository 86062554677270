export default {

    situacaoOpcoes: [
        { value: "Produção", text: "Produção" },
        { value: "Pendente", text: "Pendente" },
        { value: "Prova", text: "Prova" },
        { value: "Cancelado", text: "Cancelado" },
        { value: "Finalizado", text: "Finalizado" },
        { value: "Saiu para Entrega", text: "Saiu para Entrega" },
        { value: "Pedido não Enviado", text: "Pedido não Enviado" },
        { value: "Pedido", text: "Pedido" },
        { value: "Entregue", text: "Entregue" },
    ],

    situacaoOpcoesFiltros: [

        { value: "Produção", text: "Produção" },
        { value: "Pendente", text: "Pendente" },
        { value: "Prova", text: "Prova" },
        { value: "Cancelado", text: "Cancelado" },
        { value: "Finalizado", text: "Finalizado" },
        { value: "Saiu para Entrega", text: "Saiu para Entrega" },
        { value: "Pedido não Enviado", text: "Pedido não Enviado" },
        { value: "Pedido", text: "Pedido" },
        { value: "Entregue", text: "Entregue" },
    ],

    situacaoOpcoesLancarReceitas: [

        { value: "Produção", text: "Produção" },
        { value: "Pendente", text: "Pendente" },
        { value: "Prova", text: "Prova" },
        { value: "Cancelado", text: "Cancelado" },
        { value: "Finalizado", text: "Finalizado" },
        { value: "Saiu para Entrega", text: "Saiu para Entrega" },
        { value: "Entregue", text: "Entregue" },
        { value: "Produto", text: "Produto" },
        { value: "Transporte", text: "Transporte" },
    ],

    situacaoOpcoesFiltrosAgenda: [

        { value: "Produção", text: "Produção" },
        { value: "Pendente", text: "Pendente" },
    ],


    situacaoCor: [
        {
            'Produção': 'primary',
            'Pendente': 'light-danger',
            'Prova': 'light-warning',
            'Cancelado': 'danger',
            'Finalizado': 'light-primary',
            'Saiu para Entrega': 'light-info',
            'Pedido não Enviado': 'danger',
            'Pedido': 'light-info',
            'Entregue': 'success',
            'Produto': 'secondary',
            'Transporte': 'light-secondary',
        }
    ]
}
