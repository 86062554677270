<template>

  <b-modal
      id="editarEtapas"
      hide-footer
      centered
      size="xl"
      title="Etapas"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
  >

    <b-card >
      <b-row>
        <b-col cols="12">
          <b-alert :variant="getErrors.color" v-if="getErrors.hasMessage" show>
            <div class="alert-body" v-for="(error, index) in getErrors.messageArray" :key="index">
              {{error[0]}}
            </div>

            <div class="alert-body">{{getErrors.message}}</div>
          </b-alert>
        </b-col>
      </b-row>

      <validation-observer ref="formAnotacao">
        <b-row>
        <b-col>

          <!-- button on right -->
          <label class="d-inline">  <span class="font-weight-bold">Anotação: </span></label>
            <validation-provider
                #default="{ errors }"
                name="Anotação"
                rules="max:255"
            >
              <b-form-textarea
                  v-model="anotacao"
                  :state="errors.length > 0 ? false : null"
                  class="mb-2"
                  type="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </b-col>
      </b-row>
        <b-row>
          <b-col>
            <b-button
                variant="gradient-primary"
                @click="gravarAnotacao"
                :disabled="!anotacao"
            >
              Gravar
            </b-button>
          </b-col>
        </b-row>

      </validation-observer>
      <b-table-simple
          small
          hover
          striped
          responsive="lg"
          class="my-2"
      >
        <b-thead>
          <b-th width="30">Conluida em</b-th>
          <b-th width="190">Etapa</b-th>
          <b-th width="200">Prazo</b-th>
          <b-th width="130">Opções</b-th>
        </b-thead>

          <b-tr :variant="corTabela(etapa)" class="handle" v-for="(etapa, index) in etapas" :key="etapa.id">
            <b-td> {{ etapa.data_finalizado | converteDataHora }}</b-td>
            <b-td> {{etapa.nome_etapa}} <b-badge class="badge-glow" v-if="etapa.repeticao" variant="warning">Repetição</b-badge> </b-td>

            <b-td>
              <span class="text-bold">{{ etapa.prazo | converteData }}</span>
              <span class="text-bold" v-if="etapa.hora"> : {{ etapa.hora }}</span>

            </b-td>

            <b-td>
              <b-badge v-if="!etapa.data_finalizado" class="cursor-pointer" variant="light-primary" @click="fimEtapa(etapa, index)">
                Finalizar
              </b-badge>
              <b-badge v-else class="cursor-pointer" variant="light-success">
                Concluida
              </b-badge>
            </b-td>

          </b-tr>
        <b-tfoot >
          <b-tr class="py-4">
            <b-td colspan="7" class="text-right">
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </b-card>

    <b-row class="mt-2">
      <b-col>
        <b-button
            variant="outline-primary"
            block
            @click="verAnotacoes"
        >
          Ver Anotações
        </b-button>
      </b-col>
      <b-col>
        <b-button
            variant="outline-secondary"
            block
            @click="fechouModal"
        >
          Fechar
        </b-button>
      </b-col>
    </b-row>
    <ModalVerAnotacoes :historico="historico"/>

  </b-modal>
</template>

<script>

import {
  BTableSimple,
  BThead,
  BTh,
  BTr,
  BTd,
  BTfoot,
  BAlert,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BModal,
  BRow,
  BDropdown,
  BDropdownItem, BFormTextarea,

} from 'bootstrap-vue'

import {mapState} from 'vuex'
import {ValidationObserver, ValidationProvider} from "vee-validate";
import Cleave from "vue-cleave-component";
import vSelect from "vue-select";
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ModalVerAnotacoes from "@/views/pedidos/ModalVerAnotacoes.vue"
import {max} from '@core/utils/validations/validations'

export default {

  props: ['item', 'etapasEditar'],
  components:{
    BFormTextarea,
    BTableSimple,
    BThead,
    BTh,
    BTr,
    BTd,
    BTfoot,
    BBadge,
    BCard,
    BAlert,
    BModal,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormGroup,
    BFormDatepicker,
    Cleave,
    vSelect,
    BDropdown,
    BDropdownItem,
    ToastificationContent,
    ModalVerAnotacoes,
    ValidationObserver,
    ValidationProvider,
    max
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      anotacao: '',
      dragging: true,
      ordenar: false,
      spinner: false,
      submit: false,
      getErrors: {
        hasMessage: false,
        color: 'danger',
        message: '',
        messageArray: ''
      },
      editar: [],
      historico:[],
      fields: [
        {key: 'data_inicio', label: 'Início', thStyle: {width: '80px'}},
        {key: 'data_finalizado', label: 'Fim',thStyle: {width: '80px'}},
        {key: 'nome_etapa', label: 'Etapa'},
        {key: 'funcionario_id', label: 'Colaborador', thStyle: {minWidth: '200px'}},
        {key: 'prazo', label: 'Prazo', thStyle: {minWidth: '200px'}},
        {key: 'hora', label: 'Hora', thStyle: {width: '100px'}},
        {key: 'opcoes', label: 'Opções', thStyle: {width: '120px'}},
      ],
      options: {
        hora: {
          delimiters: [":"],
          blocks: [2, 2],
          numericOnly: true,
          time: true,
          timePattern: ["h", "m"],
        }
      },
    }
  },

  computed: {
    ...mapState({
      listaEtapas: state => state.producao.listaEtapas,
      laboratorios: state => state.laboratorios.laboSelecionado,
      user: state => state.user.user
    }),

    etapas: {

      get() {
        return this.etapasEditar
      },
      set(newValue){
        let result = []

        if(newValue.length){
          newValue.forEach( (v, index) => {
            result.push(
                {
                  ...v,
                  ordem: index + 1,
                }
            )
          })
        }

        this.editar = result
      }
    }
  },

  methods: {

    gravarAnotacao() {
      let request = {
        "id_company": this.laboratorios,
        "odonto_user_id": this.user.id,
        "os_item_id": this.item.item.items_id,
        "anotacao": this.anotacao
      }

      this.$refs.formAnotacao.validate().then( success => {
        if(success) {
          this.$http.post("odontodocs/pedidos/etapas/anotacoes", request)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Anotação gravada com sucesso!",
                  icon: "InfoIcon",
                  variant: "success",
                },
              });
              this.anotacao = ''
              this.getErrors.hasMessage = false;
            })
            .catch((error) => {
              this.getErrors = this.$responseMessages.getMessage(error, null);
            })
            .finally(() => {
              this.submit = false;
            });
        }
      })
    },
    verAnotacoes() {
      this.$http(`odontodocs/pedidos/etapas/anotacoes/${this.laboratorios}/${this.item.item.items_id}`)
        .then((res) => {
          this.getErrors.hasMessage = false;
          this.historico = res.data
          this.$bvModal.show('verAnotacoes')
        })
        .catch((error) => {
          this.getErrors = this.$responseMessages.getMessage(error, null);
        })

    },

    fechouModal(){
      this.buscaEtapa = 0
      this.$bvModal.hide('editarEtapas')
    },

    fimEtapa(etapa, index) {
      this.$http.put(`odontodocs/pedidos/etapas/finalizar/${this.laboratorios}/${etapa.id}`)
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Etapa finalizada com sucesso!",
                icon: "InfoIcon",
                variant: "success",
              },
            });
            this.mensagemErro(false)
            this.etapas[index].data_finalizado = moment().format('YYYY-MM-DD H:mm:ss')
            this.getErrors.hasMessage = false;
          })
          .catch((error) => {
            this.getErrors = this.$responseMessages.getMessage(error, null);
          })
          .finally(() => {
            this.submit = false;
          });
    },
    excluirItem(id, index) {

      this.$http.delete(`v1/etapas/${id}`).then(res => {

        const resposta = {
          item_id: this.item.item.items_id,
          index: index
        }
        this.$store.commit('producao/DELETE_ETAPAS_ENTRADAS', resposta)
        this.spinner = true
        this.submit = true
        this.getErrors.hasMessage = ''


      }).catch(error => {
        let res = this.$responseMessages.getMessage(error, null)
        this.getErrors = res
      }).finally( () => {
        this.spinner = false
        this.submit = false

      })

    },
    mensagemErro(erro, texto){
      this.getErrors.hasMessage = false
      this.getErrors.message = ''

      if(erro){
        this.getErrors.hasMessage = true
        this.getErrors.message = ` "Colaborador não informado ou diferente do selecionado" para ${texto} uma Etapa!!`
      }
    },
    corTabela(item) {
      if (item.data_finalizado) {
        return 'success'
      }
    },


  },

  filters: {
    converteData(data){
      if(!data) return
      return moment(data).format('DD/MM/YYYY')
    },
    converteDataHora(data){
      if(!data) return
      return moment(data).format('DD/MM/YYYY H:mm')
    },
  }
}

</script>

<style>

.etapa-font {
  font-size: 0.857rem;
  font-weight: bold;
}
.v-select .vs--disabled .vs__search {
  background-color: transparent;
}

</style>

